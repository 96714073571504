<template>
  <v-dialog
    v-model="modal"
    :value="isVisible"
    :max-width="dialogWidth"
    :max-height="dialogHeight"
    class="d-flex justify-center"
    z-index="1990"
    persistent>
    <v-card>
      <v-toolbar
        v-if="!dialog.params.hideToolbar"
        style="
          color: #08487a;
          font-weight: 700 !important;
          font-size: 16px;
          background-color: #fff;
          box-shadow: none;
        "
        :height="60"
        dark>
        <v-toolbar-title style="font-size: 1rem; min-width: 80%">
          {{ dialog.title }}:
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn size="small" class="rounded-circle" icon @click="closeDialog">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none">
            <path
              d="M25 13C25 6.375 19.625 1 13 1C6.375 1 1 6.375 1 13C1 19.625 6.375 25 13 25C19.625 25 25 19.625 25 13Z"
              stroke="#C0C1C7"
              stroke-width="2"
              stroke-miterlimit="10" />
            <path
              d="M17 17L9 9M9 17L17 9"
              stroke="#C0C1C7"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </v-btn>
      </v-toolbar>
      <v-card-text
        :style="{
          backgroundColor: '#ffffff !important',
          height: dialogHeight,
          overflowY: overflowY,
          overflowX: overflowX,
        }">
        <Activity
          v-if="addEvent || editEvent || deleteEvent"
          :ref="dialog.action"
          :action="dialog.action"
          :dialog="dialog">
        </Activity>
        <Education
          v-if="createEducation || deleteEducation || updateEducation"
          :ref="dialog.action"
          :state="createEducation"
          :action="dialog.action">
        </Education>
        <ApproveCalculation
          v-if="approveCalculation || rejectCalculation"
          :ref="dialog.action"
          :dialog="dialog">
        </ApproveCalculation>
        <DeleteCalculation
          v-if="dialog.action === 'deleteCalculation'"
          :ref="dialog.action"
          :dialog="dialog">
        </DeleteCalculation>
        <ApproveSlider
          v-if="approveSlider"
          :ref="dialog.action"
          :dialog="dialog">
        </ApproveSlider>
        <NewContractor
          v-if="
            [
              'newContractor',
              'deleteContractor',
              'addContractorToApplication',
              'deleteContractorFromApplication',
              'addMainContractor',
              'createMainContractor',
              'deleteMainContractor',
              'assignAgentContractor',
              'deleteAgentContractor',
            ].includes(dialog.action)
          "
          :ref="dialog.action"
          :dialog="dialog">
        </NewContractor>
        <ProjectVerification
          v-if="setRiskManagerResponsible"
          :ref="dialog.action"
          :dialog="dialog">
        </ProjectVerification>
        <RelatedPersons
          v-if="dialog.action === 'relatedPersons'"
          :ref="dialog.action"
          :dialog="dialog">
        </RelatedPersons>
        <Employee
          v-if="
            ['createEmployee', 'updateEmployee', 'deleteEmployee'].includes(
              dialog.action
            )
          "
          :ref="dialog.action"
          :dialog="dialog">
        </Employee>
        <ExecutiveAuthorities
          v-if="dialog.action === 'setExecutiveAuthorities'"
          :ref="dialog.action"
          :dialog="dialog">
        </ExecutiveAuthorities>
        <ChangeExecutiveAuthority
          v-if="dialog.action === 'changeExecutiveAuthority'"
          :ref="dialog.action"
          :dialog="dialog"></ChangeExecutiveAuthority>
        <CompanyGroup
          v-if="['editCompanyGroup', 'addCompanyGroup'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog">
        </CompanyGroup>
        <Beneficiary
          v-if="
            ['addBeneficiary', 'editBeneficiary', 'deleteBeneficiary'].includes(
              dialog.action
            )
          "
          :ref="dialog.action"
          :dialog="dialog">
        </Beneficiary>
        <SyncWithOpenData
          v-if="dialog.action === 'syncWithOpenData'"
          :ref="dialog.action"
          :dialog="dialog">
        </SyncWithOpenData>
        <BankAccount
          v-if="['addBankAccount', 'editBankAccount'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog">
        </BankAccount>
        <SignatureBasis
          v-if="dialog.action === 'signatureBasis'"
          :ref="dialog.action"
          :dialog="dialog"></SignatureBasis>
        <NewContact
          v-if="
            addContactFromApplication ||
            addContact ||
            deleteContact ||
            createContactToApplication ||
            deleteContactFromApplication ||
            deleteMainContact ||
            createContactToCareer ||
            addContactToCareer ||
            addContractorContact ||
            addApplicationContact ||
            chooseContractorContact
          "
          :ref="dialog.action"
          :dialog="dialog"
          @submit="submit">
        </NewContact>
        <Representative
          v-if="addRepresentative || editRepresentative"
          :ref="dialog.action"
          :dialog="dialog"></Representative>
        <Departments
          v-if="dialog.action === 'departments-dialog'"
          :ref="dialog.action"
          :dialog="dialog"></Departments>
        <FaqPresentation
          v-if="dialog.action === 'faq-presentation'"
          :ref="dialog.action"
          :dialog="dialog"></FaqPresentation>
        <SplitAmountOfFunding
          v-if="splitAmountOfFunding"
          :ref="dialog.action"
          :dialog="dialog">
        </SplitAmountOfFunding>
        <ContactFop
          v-if="['contact-fop'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog" />
        <ProjectMiddleManager
          v-if="assignProjectMiddleManager"
          :ref="dialog.action"
          :dialog="dialog">
        </ProjectMiddleManager>
        <ProjectMortgagor
          v-if="
            addProjectPledge ||
            deleteProjectContractorMortgagor ||
            deleteProjectContactMortgagor
          "
          :ref="dialog.action"
          :dialog="dialog">
        </ProjectMortgagor>
        <KLP
          v-if="
            [
              'addKlpHead',
              'deleteKlpHead',
              'chooseKlpParticipants',
              'chooseKlpInvited',
            ].includes(dialog.action)
          "
          :ref="dialog.action"
          :dialog="dialog">
        </KLP>
        <AdminDeleteUser
          v-if="['adminDeleteUser'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog">
        </AdminDeleteUser>
        <FrontManagerPlan
          v-if="
            ['deleteFrontSalesPlan', 'editFrontSalesPlan'].includes(
              dialog.action
            )
          "
          :ref="dialog.action"
          :dialog="dialog">
        </FrontManagerPlan>
        <FrontActivitiesPlan
          v-if="
            ['deleteFrontActivitiesPlan', 'editFrontActivitiesPlan'].includes(
              dialog.action
            )
          "
          :ref="dialog.action"
          :dialog="dialog">
        </FrontActivitiesPlan>
        <Dealer
          v-if="['addDealer', 'deleteDealer'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog">
        </Dealer>
        <DealerInactivation
          v-if="dialog.action === 'dealer-inactivation'"
          :ref="dialog.action"
          :dialog="dialog">
        </DealerInactivation>
        <SelectAddress
          v-if="['addAddress', 'editAddress'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog"></SelectAddress>
        <CopyAddress
          v-if="dialog.action === 'copyAddress'"
          :ref="dialog.action"
          :dialog="dialog"></CopyAddress>
        <DealerAgent
          v-if="
            [
              'addDealerAgent',
              'deleteDealerAgent',
              'editDealerAgent',
              'editDealerOutletContactPerson',
              'createDealerOutletContactPerson',
            ].includes(dialog.action)
          "
          :ref="dialog.action"
          :dialog="dialog">
        </DealerAgent>
        <NewAgent
          v-if="['createContactAgent'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog">
        </NewAgent>
        <SelectEntity
          v-if="dialog.action === 'selectEntity'"
          :ref="dialog.action"
          :dialog="dialog"></SelectEntity>
        <NewTicket
          v-if="['createTicket'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog">
        </NewTicket>
        <DealerSeller
          v-if="
            ['deleteDealerSeller', 'addDealerSeller'].includes(dialog.action)
          "
          :ref="dialog.action"
          :dialog="dialog">
        </DealerSeller>
        <Agent
          v-if="['addAgent', 'deleteAgent'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog">
        </Agent>
        <FrontBonusStats
          v-if="dialog.action === 'frontBonusStats'"
          :ref="dialog.action"
          :dialog="dialog">
        </FrontBonusStats>
        <BeneficiarOwner
          v-if="
            [
              'addContractorKbv',
              'createContractorKbv',
              'editContractorKbv',
              'deleteContractorKbv',
            ].includes(dialog.action)
          "
          :ref="dialog.action"
          :dialog="dialog">
        </BeneficiarOwner>
        <Passport
          v-if="['create-passport', 'edit-passport'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog" />
        <PhoneCallDetails
          v-if="dialog.action === 'phone-call-dialog'"
          :ref="dialog.action"
          :dialog="dialog" />
        <KlpSolution
          v-if="dialog.action === 'klp-solution'"
          :ref="dialog.action"
          :dialog="dialog" />
        <EditorshipPoints
          v-if="dialog.action === 'createEditorShipPoint'"
          :ref="dialog.action"
          :dialog="dialog" />
        <ManualEditorship
          v-if="dialog.action === 'editManualEditorship'"
          :ref="dialog.action"
          :dialog="dialog" />
        <EditCurrentAccount
          v-if="dialog.action === 'editCurrentAccount'"
          :ref="dialog.action"
          :dialog="dialog" />
        <AdminDirectory
          v-if="dialog.action === 'create-admin-directory'"
          :ref="dialog.action"
          :dialog="dialog" />
        <AddMarketingExpenses
          v-if="dialog.action === 'add-marketing-expenses'"
          :ref="dialog.action"
          :dialog="dialog" />
        <EditMarketingExpenses
          v-if="dialog.action === 'edit-marketing-expenses'"
          :ref="dialog.action"
          :dialog="dialog" />
        <ViewSmsDialog
          v-if="dialog.action === 'view-sms-dialog'"
          :ref="dialog.action"
          :dialog="dialog" />
        <SendSmsDialog
          v-if="dialog.action === 'send-sms-dialog'"
          :ref="dialog.action"
          :dialog="dialog" />
        <FullTaskPlanDetails
          v-if="dialog.action === 'full-task-plan-dialog'"
          :ref="dialog.action"
          :dialog="dialog" />
        <TaskPlanDetails
          v-if="dialog.action === 'task-plan-dialog'"
          :ref="dialog.action"
          :dialog="dialog" />
        <ThroughResponsible
          v-if="dialog.action === 'addThroughResponsible'"
          :ref="dialog.action"
          :dialog="dialog" />
        <TaskPlanEdit
          v-if="dialog.action === 'task-plan-edit'"
          :ref="dialog.action"
          :dialog="dialog" />
        <SelectPrintedForm
          v-if="dialog.action === 'selectPrintedForm'"
          :ref="dialog.action"
          :dialog="dialog" />
        <PaymentActCard
          v-if="dialog.action === 'paymentActCard'"
          :ref="dialog.action"
          :dialog="dialog" />
        <MarketingCompany
          v-if="dialog.action === 'createMarketingCompany'"
          :ref="dialog.action"
          :dialog="dialog" />
        <RejectApplication
          v-if="['rejectApplication'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog">
        </RejectApplication>
        <FaqVideo
          v-if="['faqCreateVideo', 'faqDeleteVideo'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog">
        </FaqVideo>
        <FaqDocument
          v-if="
            [
              'faqCreateDocument',
              'faqDeleteDocument',
              'faqEditDocument',
            ].includes(dialog.action)
          "
          :ref="dialog.action"
          :dialog="dialog">
        </FaqDocument>
        <Supervisor
          v-if="
            [
              'createSupervisor',
              'deleteSupervisor',
              'updateSupervisor',
            ].includes(dialog.action)
          "
          :ref="dialog.action"
          :dialog="dialog">
        </Supervisor>
        <SupervisorFront
          v-if="
            ['supervisorAddFront', 'supervisorDeleteFront'].includes(
              dialog.action
            )
          "
          :ref="dialog.action"
          :dialog="dialog">
        </SupervisorFront>
        <EditCareer
          v-if="['editCareer'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog">
        </EditCareer>
        <EditSpecification
          v-if="['editSpecification'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog">
        </EditSpecification>
        <RejectProject
          v-if="projectSendRequestWithComment"
          :ref="dialog.action"
          :dialog="dialog">
        </RejectProject>
        <RiskResult
          v-if="dialog.action === 'riskResult'"
          :ref="dialog.action"
          :dialog="dialog"></RiskResult>
        <KlpLimitOfFinancing
          v-if="dialog.action === 'klpLimitOfFinancing'"
          :ref="dialog.action"
          :dialog="dialog">
        </KlpLimitOfFinancing>
        <CreateLeadByCall
          v-if="dialog.action === 'createLeadByCall'"
          :ref="dialog.action"
          :dialog="dialog">
        </CreateLeadByCall>
        <HistoryLeadCalls
          v-if="dialog.action === 'historyLeadCalls'"
          :ref="dialog.action"
          :dialog="dialog">
        </HistoryLeadCalls>
        <CustomTemplates
          v-if="
            [
              'addCustomTemplates',
              'editCustomTemplates',
              'deleteCustomTemplates',
              'viewCustomTemplates',
            ].includes(dialog.action)
          "
          :ref="dialog.action"
          :dialog="dialog">
        </CustomTemplates>
        <ProjectConclusion
          v-if="dialog.action === 'showProjectConclusion'"
          :ref="dialog.action"
          :dialog="dialog">
        </ProjectConclusion>
        <CalculationChangeRate
          v-if="calculationChangeRate || calculationChangePrice"
          :ref="dialog.action"
          :dialog="dialog">
        </CalculationChangeRate>
        <CreateServiceApplication
          v-if="dialog.action === 'createServiceApplication'"
          :ref="dialog.action"
          :dialog="dialog">
        </CreateServiceApplication>
        <Contacts
          v-if="createPhone || editPhone || createEmail || editEmail"
          :ref="dialog.action"
          :dialog="dialog">
        </Contacts>
        <Scoring
          v-if="dialog.action === 'scoring-create'"
          :ref="dialog.action"
          :dialog="dialog">
        </Scoring>
        <BenefitsQuestionnaire
          v-if="benefitsQuestionnaire"
          :ref="dialog.action"
          :dialog="dialog"></BenefitsQuestionnaire>
        <QuestionnaireTemplateDialog
          v-if="questionnaireTemplateDialog"
          :ref="dialog.action"
          :dialog="dialog"></QuestionnaireTemplateDialog>
        <TerminateContract
          v-if="['terminateDfl', 'terminateDkp'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog"></TerminateContract>
        <Plan
          v-if="['createPlan', 'updatePlan'].includes(dialog.action)"
          :ref="dialog.action"
          :dialog="dialog"></Plan>
        <CarForSaleSelect
          v-if="dialog.action === 'carForSaleSelect'"
          :ref="dialog.action"
          :dialog="dialog"></CarForSaleSelect>
        <PlanManager
          v-if="
            ['createPlanManagersValue', 'updatePlanManagersValue'].includes(
              dialog.action
            )
          "
          :ref="dialog.action"
          :dialog="dialog"></PlanManager>
        <PlanValueType
          v-if="
            ['createPlanValueType', 'updatePlanValueType'].includes(
              dialog.action
            )
          "
          :ref="dialog.action"
          :dialog="dialog">
        </PlanValueType>
        <SendOffer
          v-if="dialog.action === 'sendOffer'"
          :ref="dialog.action"
          :dialog="dialog"></SendOffer>
      </v-card-text>
      <v-card-actions
        v-if="!!!dialog.params.hideActions"
        style="background: white"
        :class="[
          'pb-2',
          dialog.params.flexEnd ? 'justify-end' : 'justify-space-around',
        ]">
        <v-btn
          v-if="
            !dialog.params.hideActionsButtons &&
            !dialog.params.hideRejectButton &&
            !dialog.params.hideCancel
          "
          text
          :style="{
            width: dialog.params.btnWidth ? dialog.params.btnWidth : '45%',
            color: '#08487a',
            'font-weight': 700,
            'font-size': '12px',
            background: '#fff',
            border: '2px solid #08487a',
          }"
          :height="33"
          small
          :disabled="loading || dialog.disabled"
          @click="reject">
          {{ dialog.params.rejectText || 'ВІДХИЛИТИ' }}
        </v-btn>
        <v-btn
          v-if="!dialog.params.hideActionsButtons"
          class="text-white"
          :style="{
            width: dialog.params.btnWidth ? dialog.params.btnWidth : '45%',
            background: '#08487a',
            'border-radius': '4px',
          }"
          :height="33"
          small
          :loading="loading"
          :disabled="dialog.disabled"
          @click="submit">
          {{ dialog.params.submitText || 'ЗБЕРЕГТИ' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import SelectAddress from './components/address/SelectAddress.vue'
import CopyAddress from './components/address/CopyAddress.vue'
import CustomTemplates from './components/CustomTemplates.vue'
import ThroughResponsible from './components/ThroughResponsible.vue'
import Passport from './components/Passport.vue'
import EditMarketingExpenses from './components/EditMarketingExpenses.vue'
import AddMarketingExpenses from './components/AddMarketingExpenses.vue'
import MarketingCompany from './components/MarketingCompany.vue'
import FullTaskPlanDetails from './components/FullTaskPlanDetails.vue'
import ViewSmsDialog from './components/ViewSmsDialog.vue'
import SendSmsDialog from './components/SendSmsDialog.vue'
import PhoneCallDetails from './components/PhoneCallDetails.vue'
import TaskPlanDetails from './components/TaskPlanDetails.vue'
import TaskPlanEdit from './components/TaskPlanEdit.vue'
import Education from './components/education.vue'
import Activity from './components/activity/Activity.vue'
import ApproveCalculation from './components/ApproveCalculation.vue'
import ApproveSlider from './components/ApproveSlider.vue'
import NewContractor from './components/NewContractor.vue'
import ProjectVerification from './components/ProjectVerification.vue'
import NewContact from './components/NewContact.vue'
import SplitAmountOfFunding from './components/SplitAmountOfFunding.vue'
import ProjectMiddleManager from './components/ProjectMiddleManager.vue'
import ProjectMortgagor from './components/ProjectMortgagors.vue'
import KLP from './components/KLP.vue'
import AdminDeleteUser from './components/AdminDeleteUser.vue'
import FrontManagerPlan from './components/FrontSalesPlan.vue'
import FrontActivitiesPlan from './components/FrontActivitiesPlan.vue'
import ContactFop from './components/Fop.vue'
import Dealer from './components/Dealer.vue'
import DealerAgent from './components/dealer-agent.vue'
import NewAgent from './components/new-agent.vue'
import NewTicket from './components/new-ticket.vue'
import DealerSeller from './components/DealerSeller.vue'
import Agent from './components/Agent.vue'
import BeneficiarOwner from './components/BeneficiarOwner.vue'
import RejectApplication from './components/RejectApplication.vue'
import FaqVideo from './components/FaqVideo.vue'
import FaqDocument from './components/FaqDocument.vue'
import Supervisor from './components/Supervisor.vue'
import SupervisorFront from './components/SupervisorFront.vue'
import EditCareer from './components/EditCareer.vue'
import EditSpecification from './components/EditSpecification.vue'
import RejectProject from './components/RejectProject.vue'
import ProjectConclusion from './components/ProjectConclusion.vue'
import CalculationChangeRate from './components/CalculationChangeRate.vue'
import CreateServiceApplication from './components/CreateServiceApplication.vue'
import Representative from './components/Representative.vue'
import Contacts from './components/Contacts.vue'
import Scoring from './components/Scoring.vue'
import { mapState } from 'vuex'
import { v$Notify } from '@/utils/helperFunc'
import PaymentActCard from './components/PaymentActCard.vue'
import KlpSolution from './components/KlpSolution.vue'
import AdminDirectory from './components/AdminDirectory.vue'
import SelectPrintedForm from './components/SelectPrintedForm.vue'
import BenefitsQuestionnaire from './components/BenefitsQuestionnaire.vue'
import QuestionnaireTemplateDialog from './components/QuestionnaireTemplateDialog.vue'
import Plan from './components/plans/Plan.vue'
import PlanManager from './components/plans/PlanManager.vue'
import PlanValueType from './components/plans/PlanValueType.vue'
import Departments from './components/Departments.vue'
import DealerInactivation from './components/DealerInactivation.vue'
import KlpLimitOfFinancing from './components/KlpLimitOfFinancing.vue'
import FrontBonusStats from './components/FrontBonusStats.vue'
import CreateLeadByCall from './components/CreateLeadByCall.vue'
import HistoryLeadCalls from './components/HistoryLeadCalls.vue'
import RiskResult from './components/RiskResult.vue'
import CarForSaleSelect from './components/CarForSaleSelect.vue'
import DeleteCalculation from './components/DeleteCalculation.vue'
import TerminateContract from './components/TerminateContract.vue'
import Employee from './components/contractor/Employee.vue'
import ExecutiveAuthorities from './components/contractor/ExecutiveAuthorities.vue'
import SignatureBasis from './components/contractor/signatureBasis/SignatureBasis.vue'
import EditorshipPoints from './components/editorship/EditorshipPoints.vue'
import ManualEditorship from './components/editorship/ManualEditorship.vue'
import ChangeExecutiveAuthority from './components/contractor/ChangeExecutiveAuthority.vue'
import FaqPresentation from './components/FaqPresentation.vue'
import SendOffer from './components/send-offer/SendOffer.vue'
import SelectEntity from './components/SelectEntity.vue'
import EditCurrentAccount from '@/dialogs/components/editorship/EditCurrentAccount.vue'
import CompanyGroup from '@/dialogs/components/contractor/companyGroup/CompanyGroup.vue'
import RelatedPersons from '@/dialogs/components/project-verification/parts/RelatedPersons.vue'
import Beneficiary from '@/dialogs/components/contractor/beneficiaries/Beneficiary.vue'
import BankAccount from '@/dialogs/components/BankAccount.vue'
import SyncWithOpenData from '@/dialogs/components/contractor/edr/SyncWithOpenData.vue'

export default {
  components: {
    SyncWithOpenData,
    BankAccount,
    Beneficiary,
    RelatedPersons,
    CompanyGroup,
    EditCurrentAccount,
    SelectEntity,
    SendOffer,
    FaqPresentation,
    ManualEditorship,
    EditorshipPoints,
    SignatureBasis,
    ExecutiveAuthorities,
    Employee,
    TerminateContract,
    DeleteCalculation,
    CarForSaleSelect,
    RiskResult,
    HistoryLeadCalls,
    FrontBonusStats,
    KlpLimitOfFinancing,
    DealerInactivation,
    CustomTemplates,
    Departments,
    PlanValueType,
    PlanManager,
    Plan,
    SelectPrintedForm,
    AdminDirectory,
    KlpSolution,
    PaymentActCard,
    ThroughResponsible,
    Passport,
    MarketingCompany,
    FullTaskPlanDetails,
    TaskPlanDetails,
    TaskPlanEdit,
    PhoneCallDetails,
    SendSmsDialog,
    Activity,
    Education,
    ApproveCalculation,
    ApproveSlider,
    NewContractor,
    ProjectVerification,
    NewContact,
    SplitAmountOfFunding,
    ProjectMiddleManager,
    ProjectMortgagor,
    KLP,
    AdminDeleteUser,
    FrontManagerPlan,
    FrontActivitiesPlan,
    Dealer,
    DealerAgent,
    NewAgent,
    NewTicket,
    DealerSeller,
    Agent,
    BeneficiarOwner,
    RejectApplication,
    FaqVideo,
    FaqDocument,
    Supervisor,
    SupervisorFront,
    EditCareer,
    EditSpecification,
    RejectProject,
    ProjectConclusion,
    CalculationChangeRate,
    ViewSmsDialog,
    AddMarketingExpenses,
    EditMarketingExpenses,
    CreateServiceApplication,
    Representative,
    Contacts,
    Scoring,
    BenefitsQuestionnaire,
    QuestionnaireTemplateDialog,
    ContactFop,
    CreateLeadByCall,
    SelectAddress,
    CopyAddress,
    ChangeExecutiveAuthority,
  },
  data: () => ({
    defaultWidth: 430,
    defaultHeight: 'auto',
    loading: false,
  }),
  computed: {
    ...mapState({ dialog: state => state.dialog }),
    /** Dialog */
    modal: {
      get: function () {
        return !!this.dialog.action
      },
      set: function () {
        return this.$store.commit('closeDialog')
      },
    },
    overflowX() {
      return this.dialog?.params?.overflow?.x || 'hidden'
    },
    overflowY() {
      return this.dialog?.params?.overflow?.y || 'auto'
    },
    dialogWidth() {
      return this.dialog?.params?.dialogWidth || this.defaultWidth
    },
    dialogHeight() {
      return this.dialog?.params?.dialogHeight || this.defaultHeight
    },
    isVisible() {
      return this.dialog.title && this.dialog.action
    },
    /** Inner components */
    /* Education */
    createEducation() {
      return this.dialog.action === 'createEducation'
    },
    deleteEducation() {
      return this.dialog.action === 'deleteEducation'
    },
    updateEducation() {
      return this.dialog.action === 'updateEducation'
    },
    /* Handle calculation */
    approveCalculation() {
      return this.dialog.action === 'approveCalculation'
    },
    rejectCalculation() {
      return this.dialog.action === 'rejectCalculation'
    },
    /* ApproveSlider */
    approveSlider() {
      return this.dialog.action === 'approveSlider'
    },
    /* Contractor */
    newContractor() {
      return this.dialog.action === 'newContractor'
    },
    deleteContractor() {
      return this.dialog.action === 'deleteContractor'
    },
    addContractorToApplication() {
      return this.dialog.action === 'addContractorToApplication'
    },
    deleteContractorFromApplication() {
      return this.dialog.action === 'deleteContractorFromApplication'
    },
    addMainContractor() {
      return this.dialog.action === 'addMainContractor'
    },
    createMainContractor() {
      return this.dialog.action === 'createMainContractor'
    },
    deleteMainContractor() {
      return this.dialog.action === 'deleteMainContractor'
    },
    /* Contact */
    addContact() {
      return this.dialog.action === 'addContact'
    },
    addContactFromApplication() {
      return this.dialog.action === 'addContactFromApplication'
    },
    addApplicationContact() {
      return this.dialog.action === 'addApplicationContact'
    },
    deleteContact() {
      return this.dialog.action === 'deleteContact'
    },
    createContactToApplication() {
      return this.dialog.action === 'createContactToApplication'
    },
    deleteContactFromApplication() {
      return this.dialog.action === 'deleteContactFromApplication'
    },
    deleteMainContact() {
      return this.dialog.action === 'deleteMainContact'
    },
    createContactToCareer() {
      return this.dialog.action === 'createContactToCareer'
    },
    addContactToCareer() {
      return this.dialog.action === 'addContactToCareer'
    },
    addContractorContact() {
      return this.dialog.action === 'addContractorContact'
    },
    chooseContractorContact() {
      return this.dialog.action === 'chooseContractorContact'
    },
    addRepresentative() {
      return this.dialog.action === 'addRepresentative'
    },
    editRepresentative() {
      return this.dialog.action === 'editRepresentative'
    },
    /* Project */
    setRiskManagerResponsible() {
      return this.dialog.action === 'setRiskManagerResponsible'
    },
    assignProjectMiddleManager() {
      return this.dialog.action === 'assignProjectMiddleManager'
    },
    addProjectPledge() {
      return this.dialog.action === 'addProjectPledge'
    },
    deleteProjectContractorMortgagor() {
      return this.dialog.action === 'deleteProjectContractorMortgagor'
    },
    deleteProjectContactMortgagor() {
      return this.dialog.action === 'deleteProjectContactMortgagor'
    },
    projectSendRequestWithComment() {
      return [
        'rejectProjectResult',
        'projectToFinalization',
        'projectToMiddle',
        'sendToVerification',
        'sendToRevision',
      ].includes(this.dialog.action)
    },
    /* Events */
    addEvent() {
      return this.dialog.action === 'addEvent'
    },
    editEvent() {
      return this.dialog.action === 'editEvent'
    },
    deleteEvent() {
      return this.dialog.action === 'deleteEvent'
    },
    /** Calculator */
    splitAmountOfFunding() {
      return this.dialog.action === 'splitAmountOfFunding'
    },
    calculationChangeRate() {
      return this.dialog.action === 'calculationChangeRate'
    },
    calculationChangePrice() {
      return this.dialog.action === 'calculationChangePrice'
    },
    createPhone() {
      return this.dialog.action === 'createPhone'
    },
    editPhone() {
      return this.dialog.action === 'editPhone'
    },
    createEmail() {
      return this.dialog.action === 'createEmail'
    },
    editEmail() {
      return this.dialog.action === 'editEmail'
    },
    questionnaireTemplateDialog() {
      return this.dialog.action === 'questionnaireTemplateDialog'
    },
    /* Slider */
    benefitsQuestionnaire() {
      return [
        'createBenefitsQuestionnaire',
        'updateBenefitsQuestionnaire',
      ].includes(this.dialog.action)
    },
    /** Ref access */
    currentRef() {
      return this.$refs[this.dialog.action]
    },
  },
  methods: {
    submit() {
      if (this.currentRef?.v$?.$invalid) {
        console.log(this.currentRef?.v$)
        this.currentRef.v$.$anyError
        this.currentRef.v$.$touch()
        v$Notify(
          this.currentRef?.validation?.v$,
          this.currentRef?.validation?.name,
          this.currentRef?.validation?.payload
        )
      } else {
        return this.sendRequest()
      }
    },
    reject() {
      if (typeof this.currentRef.reject === 'function') {
        this.loading = true
        return this.currentRef
          .reject()
          .then(res => {
            this.loading = false
            const callback = this.dialog?.params?.rejectCb
            if (typeof callback === 'function') {
              callback(res)
            }
            this.$store.commit('closeDialog')
            return res
          })
          .catch(err => {
            throw err
          })
      }
      this.$store.commit('closeDialog')
    },
    closeDialog() {
      if (typeof this.currentRef.close === 'function') {
        this.loading = true
        return this.currentRef
          .close()
          .then(res => {
            this.loading = false
            const callback = this.dialog?.params?.closeCb
            if (typeof callback === 'function') {
              callback(res)
            }
            this.$store.commit('closeDialog')
            return res
          })
          .catch(err => {
            throw err
          })
      }
      this.$store.commit('closeDialog')
    },
    sendRequest() {
      this.loading = true
      const func = this.currentRef.submit()
      if (func instanceof Object && func.message) {
        this.loading = false
        return this.$setSnackbar({ text: func.message, color: 'warning' })
      }
      if (func instanceof Promise) {
        return func
          .then(res => {
            this.loading = false
            const callback = this.dialog?.params?.cb

            if (res?.data?.message && res?.data?.status !== 200) {
              return this.$setSnackbar({
                text: res.data.message,
                color: 'warning',
              })
            }
            if (callback && typeof callback === 'function') {
              callback(res)
            }

            this.$store.commit('closeDialog')
          })
          .catch(err =>
            this.$err(err, () => {
              this.loading = false
              throw err
            })
          )
      } else {
        this.loading = false
        const error = new Error(
          'this.currentRef.submit() - must return a Promise'
        )
        this.$setSnackbar({ text: error, color: 'error' })
        throw error
      }
    },
  },
  watch: {
    'dialog.action': function () {
      console.log('dialog', this.dialog)
    },
  },
}
</script>
